import React from 'react'
import PropTypes from "prop-types"
import { separateDataByGroups } from './constants/separateDataByGroups'
import { FormattedMessage } from "react-intl";
import CertificateRow from './components/CertificateRow';
import "./styles/SustainabilityAttributesAccordion.scss"
import { deleteGuionFromAttributes, handleValidationBackingAttr, handleValidationEpdAttr } from './constants/handleAttrValidations';

const SustainabilityAttributesAccordion = ({ patternData }) => {
  const { packagingFsc, sustainability, otherattributes,  manufacturingCertifications } = patternData
  const mainDataToShow = separateDataByGroups(sustainability, manufacturingCertifications, packagingFsc)
  
  return (
    <section>
      {
        mainDataToShow.map((group) => {
          return <React.Fragment key={`item-${group.id}`}>
            <table className='w-100 mt-2 mb-0'>
              <tr className='table-sustainability-row__title'>
                <td className='font-weight-bold' colSpan={4}>{group.title}</td>
              </tr>
              {
                group.attrs.map((attr) => {
                  let isEpdAttribute = handleValidationEpdAttr(attr);
                  let isBackingProfile = handleValidationBackingAttr(attr);
                  deleteGuionFromAttributes(attr);

                  return <tr key={`item__${attr.id}`}>
                    {!group.areCertificates && 
                      <td className='col-4'>
                        <FormattedMessage
                          id={`FiltersBar.${attr.title}FilterTitle`}
                        />
                      </td>
                    }

                    {!group.areCertificates &&
                      <td colSpan={2}>
                        {isBackingProfile && 
                          <img 
                            src={`${otherattributes?.certificateDomain}${attr.logoImg}`} 
                            alt={attr.title}
                            width={80} 
                          />
                        }

                        {!isEpdAttribute && attr.value}

                        {
                          isEpdAttribute && 
                          attr.value !== "" && 
                          attr.value !== true &&
                          <a href={`${otherattributes?.certificateDomain}${attr.value}`} className='table-sustainability-certificate__epd'>
                            <FormattedMessage
                              id="FiltersBar.epdCertificateFilterTitle"
                              defaultMessage="Certificate"
                            />
                          </a>
                        }
                      </td>
                    }

                    {!group.areCertificates && 
                      <td>
                        {!isEpdAttribute &&
                          attr.certificate !== "" &&
                          <a href={`${otherattributes?.certificateDomain}${attr.certificate}`} className='table-sustainability-certificate__epd'>
                            <FormattedMessage
                              id="FiltersBar.epdCertificateFilterTitle"
                              defaultMessage="Certificate"
                            />
                          </a>
                        }
                      </td>
                    }
                  </tr>
                })
              }
              {group.areCertificates &&
                <td colSpan={4}>
                  <CertificateRow
                    groupTitle={group.title.key}
                    attrs={group.attrs}
                    link={otherattributes?.certificateDomain}
                  />
                </td>}
            </table>
          </React.Fragment>
        })
      }
    </section>
  )
}


SustainabilityAttributesAccordion.propTypes = {
  patternData: PropTypes.object
};

export default SustainabilityAttributesAccordion