import PropTypes from "prop-types";
import { BACKGROUND_IMAGE_CARD_TYPE } from "modules/blog/PostsList/components/BlogPostCard/constants";
import TagInstanceContent from "utils/components/TagInstanceContent";
import BlogPostTitle from "modules/blog/PostsList/components/BlogPostCard/components/BlogPostTitle";
import { FormattedMessage } from "react-intl";
import SeeMoreButton from "utils/components/SeeMoreButton";
import Skeleton from "react-loading-skeleton";
import "./styles/OnlyImageBlogPostCard.scss";

const OnlyImageBlogPostCard = ({ post, isGrandChildren, loading = false }) => {
  return (
    <div className="only-image-blog-post-card">
      <div className="only-image-blog-post-card__photo">
        <img
          src={
            loading
              ? "https://via.placeholder.com/700x500/CCCCCC/CCCCCC"
              : post.img
          }
          alt={loading ? "..." : post.title}
          title={loading ? "..." : post.title}
        />
      </div>
      <div className="only-image-blog-post-card__content">
        <TagInstanceContent>
          {loading ? <Skeleton /> : post.instanceTag}
        </TagInstanceContent>
        {loading ? (
          <Skeleton />
        ) : (
          <BlogPostTitle
            href={post.link}
            title={post.title}
            isGrandChildren={isGrandChildren}
            cardType={BACKGROUND_IMAGE_CARD_TYPE}
          />
        )}
        <SeeMoreButton href={loading ? "#" : post.link}>
          <FormattedMessage
            id="BlogPostCard.seeMore"
            description="See more action for blog post card"
            defaultMessage="Ver más"
          />
        </SeeMoreButton>
      </div>
    </div>
  );
};

OnlyImageBlogPostCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  post: PropTypes.shape({
    id: PropTypes.string,
    layoutType: PropTypes.number.isRequired,
    instanceTag: PropTypes.string,
    title: PropTypes.string,
    shortDescription: PropTypes.string,
    link: PropTypes.string,
    img: PropTypes.string,
  }),
  isGrandChildren: PropTypes.bool,
};

export default OnlyImageBlogPostCard;
