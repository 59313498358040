export const customStylesSelctQuickShipPage = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff",
    borderColor: "#98989A",

    minHeight: "36px",
    // height: '36px',
    boxShadow: "none",
    borderRadius: "8px",
    minWidth: "145px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "36px",
    padding: "0 6px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "38px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#000000",
    fontZise: "18px",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#98989A" : "#FFF",
    color: state.isSelected ? "#FFF" : "#000",
    fontWeight: state.isSelected ? "600" : "400",
  }),
};

export const customStylesSelctCollectionQuickShipPage = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff",
    borderColor: "transparent",
    minHeight: "36px",
    // height: '36px',
    boxShadow: "none",
    borderRadius: "8px",
    minWidth: "125px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
    border: "none",
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: "36px",
    padding: "0 6px",
    color: "#000",
    // fontFamily: "Gill Sans",
    fontSize: "40px",
    fontWeight: "600",
  }),
  input: (provided) => ({
    ...provided,
    minHeight: "36px",
    margin: "0px",
    padding: "0 6px",
    color: "#000",
    fontFamily: "Gill Sans",
    fontSize: "40px",
    fontweight: "600",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    // height: '38px',
    color: "#000000",
    "& svg": {
      width: "30px", // ajusta el tamaño según lo necesites
      height: "30px", // ajusta el tamaño según lo necesites
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#000000",
    fontZise: "18px",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#98989A" : "#FFF",
    color: state.isSelected ? "#FFF" : "#000",
    fontWeight: state.isSelected ? "600" : "400",
  }),
};
