import gql from "graphql-tag";

const singlePatternZippedImagesQuery = gql`
    query SinglePatternPDFQuery($id: ID!, $instanceId: ID!, $hasInstance: Boolean) {
        pattern (id: $id) {
            id
            zippedImagesUrl(instanceId: $instanceId, hasInstance: $hasInstance)
        }
    }
`;

export default singlePatternZippedImagesQuery;