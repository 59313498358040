import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { FormattedMessage } from "react-intl";
import classNames from "classnames/bind";
import BlogPostTitle from "modules/blog/PostsList/components/BlogPostCard/components/BlogPostTitle";
import { FEATURED_CARD_TYPE } from "modules/blog/PostsList/components/BlogPostCard/constants";
import TagInstanceContent from "utils/components/TagInstanceContent";
import SeeMoreButton from "utils/components/SeeMoreButton";
import { isMARINE } from "services/instances";
import "./styles/FeaturedBlogPostCard.scss";

const FeaturedBlogPostCard = ({ post, isGrandChildren, loading = false }) => {
  return (
    <div
      className={classNames({
        "featured-blog-post-card": true,
        "featured-blog-post-card--marine": isMARINE(),
      })}
    >
      <div className="featured-blog-post-card__photo">
        <img
          src={
            loading
              ? "https://via.placeholder.com/700x500/CCCCCC/CCCCCC"
              : post.img
          }
          alt={loading ? "..." : post.title}
          title={loading ? "..." : post.title}
          className="img-fluid"
        />
      </div>
      <div className="featured-blog-post-card__content p-3 mt-4 col-6">
        <div className="w-100">
          <TagInstanceContent>
            {loading ? <Skeleton width="50px" /> : post.instanceTag}
          </TagInstanceContent>
        </div>
        <div className="w-100">
          {loading ? (
            <Skeleton width="100px" />
          ) : (
            <BlogPostTitle
              href={post.link}
              title={post.title}
              isGrandChildren={isGrandChildren}
              cardType={FEATURED_CARD_TYPE}
            />
          )}
          <p className="blog-post-title__description-text featured-blog-post-card__description-text mt-4">
            {loading ? <Skeleton count={4} width={"100%"} /> : post.summary}
          </p>
        </div>
        <div className="mt-2">
          <SeeMoreButton href={loading ? "#" : post.link}>
            <FormattedMessage
              id="BlogPostCard.seeMore"
              description="See more action for blog post card"
              defaultMessage="Ver más"
            />
          </SeeMoreButton>
        </div>
      </div>
    </div>
  );
};

FeaturedBlogPostCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  post: PropTypes.shape({
    id: PropTypes.string,
    layoutType: PropTypes.number.isRequired,
    instanceTag: PropTypes.string,
    title: PropTypes.string,
    shortDescription: PropTypes.string,
    link: PropTypes.string,
    img: PropTypes.string,
    summary: PropTypes.string,
  }),
  isGrandChildren: PropTypes.bool,
};

export default FeaturedBlogPostCard;
