import { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-apollo";
import patternsQuery from "modules/products/ProductListPage/components/ProductList/query";
import NoResultsMessage from "utils/components/NoResultsMessage";
import useProductsQueryVariables from "modules/products/ProductListPage/components/ProductList/hooks/useProductsQueryVariables";
import SeverErrorMessage from "utils/components/ServerErrorMessage";
import InfiniteScroll from "react-infinite-scroller";
import ProductsGrid from "modules/products/ProductListPage/components/ProductList/components/ProductsGrid";
import ProductsWithColors from "modules/products/ProductListPage/components/ProductList/components/ProductsWithColors";
import QueryParams from "services/browser-history/QueryParams";
import { QUERY_PARAM_GROUP, QUERY_PARAM_GROUPS_VALUES } from "../../constants";

const VIEW_AS_GRID = 1;
const VIEW_AS_LIST = 2;

export default function ProductsList() {
  const location = useLocation();
  const queryParams = useMemo(
    () => new QueryParams(location.search),
    [location.search]
  );
  const queryVariables = useProductsQueryVariables();
  const { loading, error, data, fetchMore } = useQuery(patternsQuery, {
    variables: queryVariables,
  });
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (data && data.patterns) setHasMore(data.patterns.pageInfo.hasNextPage);
  }, [data]);

  const viewAs =
    queryVariables.predominantColor ||
    queryVariables.search ||
    queryVariables.slugs
      ? VIEW_AS_LIST
      : VIEW_AS_GRID;

  /**
   * WARNING: handleDisplayProductsBySearch
   * expected case ->
   * show as list if a product color makes match with search param. So,
   * we have to make a filter to only return the matched product color because
   * graphql data returned all related products with matched pattern by search param
   */

  const handleFilterProducts = () => {
    const hasFilterByGroup =
      QUERY_PARAM_GROUPS_VALUES[queryParams.find(QUERY_PARAM_GROUP)];
    // filter by color on search
    if (queryVariables?.search) {
      const filteredData = data?.patterns.edges.map(({ node }) => {
        const filteredProducts = node.products.edges.filter(
          ({ node: productNode }) =>
            productNode.color
              ?.toLowerCase()
              .startsWith(queryVariables?.search.toLowerCase())
        );

        return {
          node: {
            ...node,
            products: {
              ...node.products,
              edges:
                filteredProducts.length > 0
                  ? filteredProducts
                  : node.products.edges,
            },
          },
        };
      });

      return filteredData;
    }

    // hand made filter by group (scholar season)
    if (hasFilterByGroup && hasFilterByGroup.filterProductsBySlug) {
      const filteredData = data?.patterns.edges.map(({ node }) => {
        const filteredProducts = node.products.edges.filter(
          ({ node: { color } }) =>
            hasFilterByGroup.filterProductsBySlug[node.slug].includes(color)
        );

        return {
          node: {
            ...node,
            products: {
              ...node.products,
              edges:
                filteredProducts.length > 0
                  ? filteredProducts
                  : node.products.edges,
            },
          },
        };
      });

      return filteredData;
    }

    return data?.patterns.edges;
  };

  function loadMore() {
    fetchMore({
      variables: {
        afterCursor:
          data && data.patterns ? data.patterns.pageInfo.endCursor : null,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.patterns.edges;
        const pageInfo = fetchMoreResult.patterns.pageInfo;

        return newEdges.length
          ? {
              ...previousResult,
              // Put the new patterns at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              patterns: {
                ...previousResult.patterns,
                edges: [...previousResult.patterns.edges, ...newEdges],
                pageInfo,
                totalCount: fetchMoreResult.patterns.totalCount,
              },
            }
          : previousResult;
      },
    });
  }

  // As I'm rendering skeleton screens as loading element (too tall) I need to know its height in order to use
  // it as the value for the threshold. That way, next page will start loading just after seeing the skeleton screen
  // appearing in the screen.
  const randomIDToComponentKeys = crypto.randomUUID(); // Line to fix duplicate products elements
  const productGridElements = document.getElementsByClassName("products-grid");
  const threshold =
    productGridElements.length > 0 ? productGridElements[0].offsetHeight : 100;

  if (error) return <SeverErrorMessage />;

  if (!loading && data?.patterns && data?.patterns.edges.length === 0)
    return <NoResultsMessage />;

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasMore}
      threshold={threshold}
      loader={
        viewAs === VIEW_AS_GRID ? (
          <ProductsGrid key={`products-grid-loading-${randomIDToComponentKeys}`} loading />
        ) : (
          <ProductsWithColors key={`products-with-colors-loading-${randomIDToComponentKeys}`} loading />
        )
      }
    >
      {viewAs === VIEW_AS_GRID ? (
        <ProductsGrid
          key={`products-grid-${randomIDToComponentKeys}`}
          patterns={data && data?.patterns ? data.patterns.edges : undefined}
        />
      ) : (
        <ProductsWithColors
          key={`products-with-colors-${randomIDToComponentKeys}`}
          patterns={data && data?.patterns ? handleFilterProducts() : undefined}
        />
      )}
    </InfiniteScroll>
  );
}
