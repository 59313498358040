import { connect } from "react-redux";
import HeaderMenuButton from "../index";
import { closeMobileMenu } from "components/Header/actions";

const mapStateToProps = (state) => {
  return {
    headerReducer: state.headerReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeMobileMenu: () => dispatch(closeMobileMenu()),
  };
};

const HeaderMenuButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderMenuButton);

export default HeaderMenuButtonContainer;
