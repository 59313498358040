import PropTypes from "prop-types";

import TagInstanceContent from "utils/components/TagInstanceContent";
import BlogPostTitle from "modules/blog/PostsList/components/BlogPostCard/components/BlogPostTitle";
import SeeMoreButton from "utils/components/SeeMoreButton";
import { FormattedMessage } from "react-intl";

import "./styles/CommonBlogPostCard.scss";
import { COMMON_CARD_TYPE } from "modules/blog/PostsList/components/BlogPostCard/constants";
import Skeleton from "react-loading-skeleton";

const CommonBlogPostCard = ({ post, isGrandChildren, loading = false }) => {
  return (
    <div className="common-blog-post-card">
      <div className="common-blog-post-card__photo">
        <img
          className="img-fluid"
          src={
            loading
              ? "https://via.placeholder.com/700x500/CCCCCC/CCCCCC"
              : post.img
          }
          alt={loading ? "..." : post.title}
          title={loading ? "..." : post.title}
        />
      </div>
      <div className="common-blog-post-card__content p-3">
        <TagInstanceContent className="tag-instance-content--dark">
          {loading ? <Skeleton width="50px" /> : post.instanceTag}
        </TagInstanceContent>
        {loading ? (
          <Skeleton width="100px" />
        ) : (
          <BlogPostTitle
            href={post.link}
            title={post.title}
            isGrandChildren={isGrandChildren}
            cardType={COMMON_CARD_TYPE}
          />
        )}
        <p className="blog-post-title__description-text mt-4">
          {loading ? <Skeleton count={4} /> : post.summary}
        </p>
        <div className="mt-2">
          <SeeMoreButton
            href={loading ? "#" : post.link}
            className="see-more-button--reversed"
          >
            <FormattedMessage
              id="BlogPostCard.seeMore"
              description="See more action for blog post card"
              defaultMessage="Ver más"
            />
          </SeeMoreButton>
        </div>
      </div>
    </div>
  );
};

CommonBlogPostCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  post: PropTypes.shape({
    id: PropTypes.string,
    layoutType: PropTypes.number.isRequired,
    instanceTag: PropTypes.string,
    title: PropTypes.string,
    shortDescription: PropTypes.string,
    link: PropTypes.string,
    img: PropTypes.string,
    summary: PropTypes.string,
  }),
  isGrandChildren: PropTypes.bool,
};

export default CommonBlogPostCard;
