import PropTypes from "prop-types";
import FullWidthArticle from "utils/components/FullWidthArticles/components/FullWidthArticle";

const FullWidthArticles = ({ articles = [] }) => (
  <div className="fw-articles">
    {articles.map((article, index) => (
      <FullWidthArticle article={article} key={index} />
    ))}
  </div>
);

FullWidthArticles.propTypes = {
  articles: PropTypes.array.isRequired,
};

export default FullWidthArticles;
