import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames/bind";
import "./styles/HeaderMainMenu.scss";
import HeaderMenuButtonContainer from "components/Header/components/HeaderMainMenu/components/HeaderMenuButton/container";
import HeaderDropdownContainer from "components/Header/components/HeaderMainMenu/components/HeaderDropdown/container";
import HeaderProductsDropdownContainer from "components/Header/components/HeaderMainMenu/components/HeaderProductsDropdown/container";
import {
  ABOUT_ECOSENSE,
  ABOUT_WE_ARE_SPRADLING,
  CONTACT_TALK_TO_US,
  DOCUMENT_LIBRARY_IN_MENU,
  HOME_PAGE,
  PRODUCTS_LIBRARY,
  PROJECTS_LIST,
  SERVICES_DETAILS,
  STORE_LOCATIONS,
} from "routes";
import { useQuery } from "react-apollo";
import marketsSimpleQuery from "components/Header/components/HeaderMainMenu/query";
import {
  getCurrentInstanceId,
  isMARINE,
  isLATAM,
  isMEXICO,
  getTranslatedLink,
  isRESOURCES,
  isUSA,
} from "services/instances";
import { FETCH_POLICY_CACHE_AND_NETWORK } from "apollo/constants";
import { shouldRenderStoreFinder } from "modules/stores/WhereToBuyPage/components/StoreFinder";
import { ABOUT_LINKS } from "./constants/about-links";
import servicesQuery from "modules/Home/components/ServicesLines/query";
import useMediaQuery from "utils/hooks/useMediaQuery";
import HeaderSubInstancesUsa from "../HeaderSubInstancesUsa";

export default function HeaderMainMenu({ headerReducer }) {
  const intl = useIntl();
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const { loading: loadingServices, data: dataServices } = useQuery(
    servicesQuery,
    {
      variables: {
        orderBy: "order",
      },
      fetchPolicy: FETCH_POLICY_CACHE_AND_NETWORK,
    }
  );
  const { loading: loadingMarkets, data: dataMarkets } = useQuery(
    marketsSimpleQuery,
    {
      variables: {
        instanceIds: [getCurrentInstanceId()],
        saleable: true,
      },
      fetchPolicy: FETCH_POLICY_CACHE_AND_NETWORK,
    }
  );

  const hasNotHeaderCartSection = isLATAM() || isMEXICO() || isMARINE();
  const hasHeaderSubInstancesUsa = isUSA() || isMARINE() || isRESOURCES();

  return (
    <div
      className={classNames({
        "header-main-menu": true,
        "header-main-menu--no-header-cart": hasNotHeaderCartSection,
        "header-main-menu--open": headerReducer.mobileMenuIsOpen,
      })}
    >
      {!isDesktop && hasHeaderSubInstancesUsa && (
        <HeaderSubInstancesUsa isDesktop={isDesktop} />
      )}
      <ul className="main-menu-sections">
        {isRESOURCES() && (
          <li
            className={classNames({
              "main-menu-sections__section": true,
              "main-menu-sections__section--marine":
                isMARINE() || isRESOURCES(),
            })}
          >
            {loadingServices && !dataServices?.services ? (
              <button
                className="header-menu-button header-products-dropdown--disabled"
                disabled
              >
                <FormattedMessage
                  id="HeaderMainMenu.services"
                  description="Services button label at header main menu"
                  defaultMessage="Services"
                />
              </button>
            ) : (
              <HeaderMenuButtonContainer
                to={getTranslatedLink(HOME_PAGE, {})}
                haveActiveClass={false}
                text={
                  <FormattedMessage
                    id="HeaderMainMenu.services"
                    description="Services button label at header main menu"
                    defaultMessage="Services"
                  />
                }
                renderDropdown={(isOpen, handleDropdownLinkClick) => (
                  <HeaderDropdownContainer
                    isOpen={isOpen}
                    onLinkClick={handleDropdownLinkClick}
                    links={dataServices.services.edges.map(
                      ({ node: service }) => ({
                        text: service.name,
                        to: getTranslatedLink(SERVICES_DETAILS, {
                          serviceSlug: service.slug,
                        }),
                      })
                    )}
                  />
                )}
                showDropdownOnMouseEnter
              />
            )}
          </li>
        )}
        <li
          className={classNames({
            "main-menu-sections__section": true,
            "main-menu-sections__section--marine": isMARINE() || isRESOURCES(),
          })}
        >
          {loadingMarkets && !dataMarkets?.markets ? (
            <button
              className="header-menu-button header-products-dropdown--disabled"
              disabled
            >
              <FormattedMessage
                id="HeaderMainMenu.products"
                description="Products button label at header main menu"
                defaultMessage="Productos"
              />
            </button>
          ) : (
            <HeaderMenuButtonContainer
              to={getTranslatedLink(PRODUCTS_LIBRARY, {})}
              text={
                <FormattedMessage
                  id="HeaderMainMenu.products"
                  description="Products button label at header main menu"
                  defaultMessage="Productos"
                />
              }
              renderDropdown={
                !isRESOURCES()
                  ? (isOpen, handleDropdownLinkClick) => (
                      <HeaderProductsDropdownContainer
                        isOpen={isOpen}
                        markets={dataMarkets?.markets}
                        onLinkClick={handleDropdownLinkClick}
                      />
                    )
                  : () => {}
              }
              showDropdownOnMouseEnter
            />
          )}
        </li>
        <li
          className={classNames({
            "main-menu-sections__section": true,
            "main-menu-sections__section--marine": isMARINE() || isRESOURCES(),
          })}
        >
          <HeaderMenuButtonContainer
            to={getTranslatedLink(ABOUT_WE_ARE_SPRADLING, {})}
            text={
              <FormattedMessage
                id="HeaderMainMenu.about"
                description="About button label at header main menu"
                defaultMessage="Sobre Spradling"
              />
            }
            showDropdownOnMouseEnter
            renderDropdown={(isOpen, handleDropdownLinkClick) => (
              <HeaderDropdownContainer
                isOpen={isOpen}
                onLinkClick={handleDropdownLinkClick}
                links={ABOUT_LINKS({ intl })[getCurrentInstanceId()]}
              />
            )}
          />
        </li>
        <li
          className={classNames({
            "main-menu-sections__section": true,
            "main-menu-sections__section--marine": isMARINE() || isRESOURCES(),
          })}
        >
          <HeaderMenuButtonContainer
            to={getTranslatedLink(DOCUMENT_LIBRARY_IN_MENU, {})}
            text={
              <FormattedMessage
                id="HeaderMainMenu.resources"
                description="Resources button label at header main menu"
                defaultMessage="Recursos"
              />
            }
          />
        </li>
        <li
          className={classNames({
            "main-menu-sections__section": true,
            "main-menu-sections__section--marine": isMARINE() || isRESOURCES(),
          })}
        >
          <HeaderMenuButtonContainer
            to={getTranslatedLink(PROJECTS_LIST, {})}
            text={
              <FormattedMessage
                id="HeaderMainMenu.projects"
                description="Projects button label at header main menu"
                defaultMessage="Proyectos"
              />
            }
          />
        </li>
        <li
          className={classNames({
            "main-menu-sections__section": true,
            "main-menu-sections__section--marine": isMARINE() || isRESOURCES(),
          })}
        >
          <HeaderMenuButtonContainer
            to={getTranslatedLink(ABOUT_ECOSENSE, {})}
            text={
              <FormattedMessage
                id="HeaderMainMenu.sustainability"
                description="Sustainability button label at header main menu"
                defaultMessage="Sostenibilidad"
              />
            }
          />
        </li>
        <li
          className={classNames({
            "main-menu-sections__section": true,
            "main-menu-sections__section--marine": isMARINE() || isRESOURCES(),
          })}
        >
          <HeaderMenuButtonContainer
            to={getTranslatedLink(CONTACT_TALK_TO_US, {})}
            text={
              <FormattedMessage
                id="HeaderMainMenu.contact"
                description="Contact button label at header main menu"
                defaultMessage="Contacto"
              />
            }
          />
        </li>
      </ul>
      {/* Se oculta este boton solo para la instacia de latam "la" */}
      {shouldRenderStoreFinder() && (!isLATAM() || isMEXICO()) && (
        <ul className="main-menu-sections">
          <li className="main-menu-sections__section">
            <HeaderMenuButtonContainer
              to={getTranslatedLink(STORE_LOCATIONS, {})}
              text={
                <FormattedMessage
                  id="HeaderMainMenu.whereToBuy"
                  description="Where to buy button label at header main menu"
                  defaultMessage="Dónde comprar"
                />
              }
            />
          </li>
        </ul>
      )}
    </div>
  );
}

HeaderMainMenu.propTypes = {
  headerReducer: PropTypes.shape({
    mobileMenuIsOpen: PropTypes.bool,
  }),
};
