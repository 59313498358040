import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import LocationsListHeaderContainer from "modules/stores/WhereToBuyPage/components/StoreFinder/components/LocationsList/components/LocationsListHeader/container";
import classNames from "classnames/bind";
import DetailedStoreBox from "./components/DetailedStoreBox";
import { useQuery } from "@apollo/react-hooks";
import StoreFinderBoxLayout from "modules/stores/WhereToBuyPage/components/StoreFinder/components/StoreFinderBoxLayout";
import { FormattedMessage } from "react-intl";
import storesByPatternQuery from "modules/stores/WhereToBuyPage/components/StoreFinder/components/LocationsList/query";
import storesByInstanceQuery from "modules/stores/WhereToBuyPage/components/StoreFinder/components/SearchOnlyByLocation/query";
import TitleUnderlined from "utils/components/TitleUnderlined";
import { getCurrentInstanceId } from "services/instances";
import "./styles/LocationsList.scss";

export default function LocationsList({
  patternId,
  cityId,
  nearbyStores,
  highlightedStoreId,
  onClickBack,
  onClickPrevious,
  setNearbyStores,
  setHighlightedStoreId,
}) {
  const [detailedStore, setDetailedStore] = useState(null);
  const query = !patternId ? storesByInstanceQuery : storesByPatternQuery;
  const commonVariables = { instanceId: getCurrentInstanceId(), active: true };
  const variables = !patternId
    ? commonVariables
    : { patternIds: [patternId], ...commonVariables };

  useQuery(query, {
    variables,
    onCompleted(data) {
      const { stores } = data;
      let nearbyStores = stores.edges;
      if (cityId)
        nearbyStores = _.filter(
          stores.edges,
          ({ node }) => node.city.id === cityId
        );
      setNearbyStores(nearbyStores);
    },
  });

  const memoizedScrollToCard = useCallback(scrollToCard, []);

  useEffect(() => {
    if (highlightedStoreId) memoizedScrollToCard(highlightedStoreId);
  }, [highlightedStoreId, memoizedScrollToCard]);

  /**
   * Scrolls to a card inside of the list
   * @param {String} storeId
   */
  function scrollToCard(storeId) {
    const card = document.getElementById(storeId);
    const offsetTop = card.offsetTop;
    document.getElementById("locations-list-store-cards").scrollTop =
      offsetTop - 60;
  }

  /**
   * Handle click on the info icon to highlight the store
   * @param {Object} event
   * @param {Object} store
   */
  function handleInfoIconClick(event, store) {
    event.stopPropagation();
    setDetailedStore(store);
  }

  function handleDetailedBoxCloseClick() {
    setDetailedStore(null);
  }

  /**
   * Handles clicks on the whole store card to see highlight it here and on the map
   * @param {Object} store
   */
  function handleStoreCardClick(store) {
    setHighlightedStoreId(store.id);
  }
  
  return (
    <StoreFinderBoxLayout
      title={
        !patternId ? (
          <FormattedMessage
            id="SearchByLocation.title"
            defaultMessage="Distribuidores por ubicación"
          />
        ) : (
          <FormattedMessage
            id="LocationsList.title.product"
            defaultMessage="Distribuidores por producto"
          />
        )
      }
      icon={!patternId ? "store" : "fabric"}
      noPadding
      goBack={onClickPrevious}
    >
      <LocationsListHeaderContainer
        patternId={patternId}
        cityId={cityId}
        onClickBack={onClickBack}
      />
      <div className="locations-list-stores">
        <div
          id="locations-list-store-cards"
          className="locations-list-stores__cards"
        >
          {nearbyStores && nearbyStores.length === 0 && (
            <div className="no-results d-flex align-items-center">
              <div className="p-3">
                <TitleUnderlined>
                  <FormattedMessage
                    id="LocationsList.noResultsTitle"
                    defaultMessage="Lo sentimos..."
                  />
                </TitleUnderlined>
                <p className="px-5 text-center">
                  <FormattedMessage
                    id="LocationsList.noResultsSubtitle"
                    defaultMessage="No hay distribuidores para la búsqueda actual"
                  />
                </p>
              </div>
            </div>
          )}
          {nearbyStores &&
            nearbyStores.map(({ node }, index) => (
              <div
                key={index}
                className={classNames({
                  "store-card": true,
                  "store-card--highlighted": node.id === highlightedStoreId,
                })}
                id={node.id}
                onClick={() => handleStoreCardClick(node)}
              >
                <div className="row">
                  <div className="col-10">
                    <p className="mb-0">
                      <strong>{node.name}</strong>
                    </p>
                    <p className="mb-0 gray">
                      <small>
                        {node.address && (
                          <>
                            <span>
                              {node.address} {node.city.name},{" "}
                              {node.city.country.name}
                            </span>
                            <br />
                          </>
                        )}
                        {node.phoneNumbers && (
                          <>
                            <span>{node.phoneNumbers}</span>
                            <br />
                          </>
                        )}
                        {node.email && (
                          <>
                            <span>
                              <a
                                href={`mailto:${node.email}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {node.email}
                              </a>
                            </span>
                            <br />
                          </>
                        )}
                        {/*{
                            node.website &&
                            <React.>
                              <span><a href={node.website}>{node.website}</a></span>
                            </React.>
                          }*/}
                      </small>
                    </p>
                    {/*<BusinessHours
                          businessHours={node.businessHours.edges.map(businessHour => businessHour.node)}/>*/}
                  </div>
                  <div className="col-2 text-right">
                    <i
                      onClick={(event) => handleInfoIconClick(event, node)}
                      className="fa fa-info-circle fa-2x"
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
        {detailedStore && (
          <DetailedStoreBox
            store={detailedStore}
            onClose={handleDetailedBoxCloseClick}
          />
        )}
      </div>
    </StoreFinderBoxLayout>
  );
}

LocationsList.propTypes = {
  patternId: PropTypes.string.isRequired,
  cityId: PropTypes.string.isRequired,
  nearbyStores: PropTypes.array,
  highlightedStoreId: PropTypes.string.isRequired,
  onClickBack: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  setNearbyStores: PropTypes.func.isRequired,
  setHighlightedStoreId: PropTypes.func.isRequired,
};
