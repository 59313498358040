import { FormattedMessage } from "react-intl";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import SpradlingTimeline from "modules/about/WeAreSpradlingPage/components/SpradlingTimeline";
import HomeAbout from "modules/Home/components/HomeAbout";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import TitleUnderlined from "utils/components/TitleUnderlined";
import {
  getTranslatedLink,
  isLATAM,
  isMARINE,
  isMEXICO,
} from "services/instances";
import SEO from "services/seo/components/SEO";
import { WE_ARE_SPRADLING_SCENE } from "services/seo/constants/scenes";
import HeroSlider from "./components/HeroSlider";
import manufactoryColombia from "./img/manufactory-colombia.jpg";
import manufactoryCostaRica from "./img/manufactory-costa-rica.jpg";
import "./styles/we-are-spradling.scss";
import { Button } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ABOUT_COMPANY_PLANTS } from "routes";

const HOME_ABOUT_DESCRIPTION_MAIN = (
  <FormattedMessage
    id="HomeAbout.description.v2"
    description="Text in about section"
    defaultMessage="Más de 65 años fabricando telas y pisos recubiertos y 50 años de exportación nos convierten en una de las empresas líderes del sector industrial. La combinación de nuestro ADN sostenible, la experiencia en la industria, el conocimiento en diseño, las tecnologías que desarrollamos, unidas a la capacidad de producción y servicio, garantizan que nuestros productos, presentes en más de 80 países,  estén a la altura de las exigencias del mercado. Somos Spradling, inspiración que recubre el mundo."
  />
);

export default function WeAreSpradlingPage() {
  const latamInstance = isLATAM() || isMEXICO();

  return (
    <div className="we-are-spradling">
      <ScrollTopOnMount />
      <HeroSlider />
      <HomeAbout
        className="py-4"
        classNameMainCol="pt-0"
        showButton={false}
        subHeroTheme={!isMARINE() ? "Purple, Lavander" : "prussian-blue"}
        description={HOME_ABOUT_DESCRIPTION_MAIN}
      />
      <section className="container text-left py-4">
        <div className="row justify-content-lg-start">
          <div className="col-12 text-center mb-4 mb-lg-3">
            <TitleUnderlined
              hTag={H2_TAG}
              className="text-size-h1 text-weight-bold mb-5"
            >
              <FormattedMessage
                id="WeAreSpradlingPage.Manufatories.title"
                description="We are spradling page title Manufatories"
                defaultMessage="La magia de la manufactura"
              />
            </TitleUnderlined>
          </div>
        </div>
        <div className="row justify-content-evently">
          <div className="col-12 col-md-6">
            <div>
              <div className="position-relative">
                <img
                  src={manufactoryColombia}
                  id="img"
                  className="manufatory-img"
                />
                <p className="manufatory-label">
                  <FormattedMessage
                    id="WeAreSpradlingPage.Manufatories.colombia.title"
                    defaultMessage="Planta Colombia"
                  />
                </p>
              </div>
              <p className="p-2">
                <FormattedMessage
                  id="WeAreSpradlingPage.Manufatories.colombia.description"
                  defaultMessage="Nuestra planta ubicada en Bogotá, Colombia, produce más de 18 millones de metros al año. Pionera en el desarrollo de telas y pisos recubiertos."
                />
              </p>
            </div>

            {/* SE OCULTA TEMPORALMENTE EL BOTON */}
            {latamInstance && (
              <div className="d-flex mb-5 mb-lg-0 justify-content-center">
                <Link
                  to={getTranslatedLink(ABOUT_COMPANY_PLANTS, {
                    plantSlug: "colombia",
                  })}
                >
                  <Button>
                    <FormattedMessage
                      id="WeAreSpraldinPage.Manufactories.knowMoreBtn"
                      defaultMessage="Know more"
                    />
                  </Button>
                </Link>
              </div>
            )}
          </div>
          <div className="col-12 col-md-6">
            <div>
              <div className="position-relative">
                <img
                  src={manufactoryCostaRica}
                  id="img"
                  className="manufatory-img"
                />
                <p className="manufatory-label">
                  <FormattedMessage
                    id="WeAreSpradlingPage.Manufatories.costaRica.title"
                    defaultMessage="Planta Costa Rica"
                  />
                </p>
              </div>
              <p className="p-2">
                <FormattedMessage
                  id="WeAreSpradlingPage.Manufatories.costaRica.description"
                  defaultMessage="Ubicada en Alajuela, San José, nuestra planta en Costa Rica produce más de 10 millones de metros al año. <strong>Su tecnología de punta la hace una de las plantas más importantes de Centro América.</strong>"
                  values={{
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </p>
            </div>

            {/* SE OCULTA TEMPORALMENTE EL BOTON */}
            {latamInstance && (
              <div className="d-flex justify-content-center">
                <Link
                  to={getTranslatedLink(ABOUT_COMPANY_PLANTS, {
                    plantSlug: "costa-rica",
                  })}
                >
                  <Button>
                    <FormattedMessage
                      id="WeAreSpraldinPage.Manufactories.knowMoreBtn"
                      defaultMessage="Know more"
                    />
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="container text-left py-4">
        <div className="row justify-content-lg-start">
          <div className="col-12 text-center mb-4 mb-lg-3">
            <TitleUnderlined
              hTag={H2_TAG}
              className="text-size-h1 text-weight-bold mb-5"
            >
              <FormattedMessage
                id="WeAreSpradlingPage.title2"
                description="We are spradling page title 2"
                defaultMessage="Lo que somos, creemos y hacemos"
              />
            </TitleUnderlined>
            <FormattedMessage
              id="WeAreSpradlingPage.content2"
              description="We are spradling page content 2"
              defaultMessage={
                "<p>Somos la unión de visiones locales con espíritu global, que día a día trabaja con la ilusión de inspirar alrededor del mundo. Creamos diseños que despiertan emociones, realzan los espacios y perduran en el tiempo. Nos apasiona lograr que lo que ayer no existía, hoy sea una realidad.</p>" +
                "<p>Creemos en el poder de la inspiración.</p>" +
                "<p>Sueños que inspiran ideas extraordinarias</p>" +
                "<p>Colores que inspiran colecciones impactantes</p>" +
                "<p>Texturas que inspiran nuevas sensaciones</p>" +
                "<p>Necesidades que inspiran un alto desempeño</p>" +
                "<p>Versatilidad que inspira un mundo de aplicaciones</p>" +
                "<p>Confianza que inspira relaciones de largo plazo</p>" +
                "<p>Conciencia que inspira un espíritu sostenible</p>" +
                "<p>Personas que inspiran lo que hacemos todos los días</p>" +
                "<p><strong>Spradling, Inspiración que recubre al mundo</strong></p>"
              }
              values={{
                p: (...chunks) => <p>{chunks}</p>,
                strong: (...chunks) => <strong>{chunks}</strong>,
              }}
            />
          </div>
        </div>
        <div className="pt-4">
          <SpradlingTimeline />
        </div>
      </div>
      <SEO scene={WE_ARE_SPRADLING_SCENE} />
    </div>
  );
}
