import PropTypes from "prop-types";
import "./styles/sample-card.scss";
import { useContext } from "react";
import CollectionContext from "modules/products/QuickshipPage/paramSlugPage/context/CollectionContext";
import { capitalizeWords } from "modules/products/QuickshipPage/utils/capitalizeWords";

const SampleCard = ({ sample }) => {
  const { setSelectedItemCollection } = useContext(CollectionContext);

  const handleUpdateSampleState = (sample) => {
    console.log(`🚀🚀🚀 ~ handleUpdateSampleState ~ sample:`, sample);
    setSelectedItemCollection(sample);
  };

  const { selectedItemCollection } = useContext(CollectionContext);

  return (
    <div
      className={"card-color container-card--masiala"}
      style={{
        border:
          sample?.product?.id === selectedItemCollection?.product?.id
            ? "2px solid #98989A"
            : "2px solid #ffffff",
      }}
      onClick={() => handleUpdateSampleState(sample)}
    >
      <div className="d-flex align-items-center justify-content-center position-relative">
        <img
          className="img-card--masiala"
          src={sample?.product?.imageForProduction}
          alt="sample-image"
        />
        <p className="name-collection">
          {capitalizeWords(sample?.product?.pattern?.name)}
        </p>
      </div>
      <div className="card-container-info--masiala">
        <p className="card-label--masiala">
          {capitalizeWords(sample?.product?.color)}
        </p>
        <p className="card-stock--masiala">{sample?.product?.stock}</p>
      </div>
    </div>
  );
};

SampleCard.propTypes = {
  staticSampleData: PropTypes.object,
  sample: PropTypes.object,
  handleClick: PropTypes.func,
};

export default SampleCard;
