import gql from "graphql-tag";
import singlePatternFragment from "utils/fragments/SinglePatternFragment";
import singlePatternGeneralFragment from "utils/fragments/SinglePatternGeneralFragment";
import singlePatternSustainabilityAttributesFragment from "utils/fragments/SinglePatternSustainabilityAttributesFragment";

export const singlePatternQuery = gql`
  query SinglePatternQuery(
    $id: ID
    $slug: String
    $unitSystem: Int
    $instanceIds: [ID]
  ) {
    pattern(id: $id, slug: $slug) {
      ...SinglePatternFragment
      hasStock
      cataloguesAvailable
      code
      maintenanceGuide
      transformationGuide
      catalogStockNumber
      saleable
      productcategorization {
        design
      }
      memo {
        id
        size
      }
      products(instances: $instanceIds) {
        totalCount
        edges {
          node {
            id
            code
            color
            predominantColors
            hasStock
            samplesAvailable
            stock
            pantone
            imageThumbSmall
            imageThumbMedium
            imageThumbLarge
            isNew
          }
        }
      }
      specsSheetData(
        fullVersion: false
        unitSystem: $unitSystem
        limitAdditionalTests: true
      )
      otherattributes {
        certificateDomain
      }
      packagingFsc
      sustainability {
        ...SinglePatternSustainabilityAttributesFragment
      }
      manufacturingCertifications {
        id
        name
        logo
        isSustainable
      }
      sectors {
        edges {
          node {
            id
            name
            market {
              id
              name
            }
          }
        }
      }
      relatedPatterns(products_Instances: $instanceIds) {
        totalCount
      }
      stores {
        totalCount
      }
    }
  }
  ${singlePatternFragment}${singlePatternSustainabilityAttributesFragment}
`;

export const relatedPatternsQuery = gql`
  query RelatedPatternQuery(
    $id: ID
    $slug: String
    $instanceIds: [ID]
    $productFirst: Int
    $saleable: Boolean
  ) {
    pattern(id: $id, slug: $slug) {
      ...SinglePatternFragment
      relatedPatterns(products_Instances: $instanceIds, saleable: $saleable) {
        edges {
          node {
            ...SinglePatternFragment
            imageThumbSmall
            products(first: $productFirst, instances: $instanceIds) {
              totalCount
              edges {
                node {
                  id
                  code
                  color
                }
              }
            }
          }
        }
      }
    }
  }
  ${singlePatternFragment}
`;

export const singlePatternGeneralQuery = gql`
  query SinglePatternGeneralQuery($id: ID!) {
    pattern(id: $id) {
      id
      general {
        ...SinglePatternGeneralFragment
        rollLength
      }
    }
  }
  ${singlePatternGeneralFragment}
`;
